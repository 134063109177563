<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head :title="item.name" :image="item.image" :tabs="tabs" back="Convention.Schedule.Lobbies">

		<app-content-head-actions-item text="Subbed" :active="true" :loading="is.subscribing" icon="subscribe" v-on:click="onUnsubscribeClick" v-if="isSubscribed" />
		<app-content-head-actions-item text="Subscribe" :loading="is.subscribing" icon="subscribe" v-on:click="onSubscribeClick" v-if="!isSubscribed" />

	</app-content-head>

	<app-content-body :is-grid="2" :loading="is.loading && is.initialised">

		<app-widget-summary :text="item.description" :image="item.image" :metrics="metrics" />
		
		<app-widget-chat :id="item.id" />

		<app-widget-events :id="item.id" />

	</app-content-body>

</app-content>

</template>

<script>

import mixItem from '@/mixin/item'

export default {

	mixins: [mixItem],

	data: function() {

		return {
			is: {
				subscribing: false
			},
			endpoint: 'convention/schedule/lobby',
			live: 'convention/schedule/lobby'
		}

	},

	computed: {

		isSubscribed: function() {

			return (this.item) ? this.item.user.subscribed : false

		},

		metrics: function() {

			return [
				{
					text: 'Total events',
					value: this.item.count.events
				},
				{
					text: 'Open events',
					value: this.item.count.open
				}
			]

		},

		tabs: function() {

			return [
				{ name: 'Convention.Schedule.Lobby', text: 'Overview' },
				{ name: 'Convention.Schedule.Lobby.Events', params: { lobby: this.item.id }, text: 'Events' }
			]

		}

	},

	methods: {

		onSubscribeClick: function() {

			this.is.subscribing = true

			this.$api.post(['convention/schedule/lobby', this.item.id, 'subscribe']).then(function() {

				this.is.subscribing = false

			}.bind(this))

		},

		onUnsubscribeClick: function() {

			this.is.subscribing = true

			this.$api.delete(['convention/schedule/lobby', this.item.id, 'subscribe']).then(function() {

				this.is.subscribing = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>